<template>
  <Onboardlayout>
    <template #header>
      <Header>
        <template #right-header-component>
          <ProfileDropDown/>
        </template>
      </Header>
    </template>
    <template #main-content>
      <CompanyAdminPage/>
    </template>
  </Onboardlayout>
</template>

<script>
import Onboardlayout from "@/components/onboarding/layouts/Onboardlayout";
import Header from "@/components/onboarding/reuseables/Header";
import ProfileDropDown from "@/components/dashboard/reuseables/ProfileDropDown";
import CompanyAdminPage from "@/components/dashboard/components/CompanyAdminPage";
export default {
  name: "EditCompanyAdminView",
  components: {CompanyAdminPage, ProfileDropDown, Header, Onboardlayout}
}
</script>

<style scoped>

</style>