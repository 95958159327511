<template>
  <div>
    <div class="lg:tw-flex lg:tw-justify-between tw-flex-col tw-px-6 lg:tw-px-52">

      <div class="tw-hidden lg:tw-pt-9 lg:tw-block">
        <button class="tw-flex" @click="$router.go(-1)">
          <ChevronLeftIcon/>
          <p class="tw-font-bold tw-text-base">Back</p>
        </button>
      </div>

      <div class="tw-flex tw-mt-10 lg:tw-mt-0 tw-justify-between">
        <p class="admin">Admins</p>
        <InviteCompanyAdminModal/>
      </div>

      <div class="tw-mt-9 lg:tw-flex tw-justify-between">
        <p class="number">{{ getCompanyAdmin.length }} {{ getCompanyAdmin.length > 1 ?'Admins' : 'Admin' }}</p>
        <div class="tw-flex">
          <p class="role">Role: </p>
          <div class="tw-w-32 tw-mr-2 filter align-center">
            <v-menu bottom offset-y left class='menuFont'>
              <template v-slot:activator='{ on, attrs }'>
                <td class='tw-flex align-center'>
                  <div class='tw-flex align-center tw-w-full' v-bind='attrs' v-on='on'>
<!--                    <vue-feather style='width: 15px; color: #475661;' class='mr-2 filterFont' type='filter'/>-->
                    <small class='pt-3 pl-2 filterFont'>{{sort?sort:'All'}}</small>
                    <vue-feather style='color: #475661; width: 15px' type='chevron-down' class="pt-2 pr-2"/>
                  </div>
                </td>
              </template>
              <v-list class='tw-cursor-pointer checkFont'>
                <v-list-item v-for='(sort,i) in sortBy' :key='i' @click="filterData(sort.label)">
                  <span>{{ sort.label }}</span>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="tw-w-60">
            <v-text-field v-model="search" hide-details label="Search" solo></v-text-field>
          </div>
        </div>
      </div>
      <div class="tw-mt-9 tw-col-6 card-table">
        <v-data-table
            :headers="headers"
            :hide-default-footer="getCompanyAdmin.length > 0 ? false : true"
            :items="newData"
            :items-per-page="5"
            :mobile-breakpoint="0"
            :search="search">

          <template #header.full_name>
            <span style="font-family: DM Sans, serif">ADMIN</span>
          </template>

          <template #header.admin_type>
            <span style="font-family: DM Sans, serif">ROLE</span>
          </template>

          <template #header.date_invited>
            <span style="font-family: DM Sans, serif">DATE INVITED</span>
          </template>

<!--          <template v-slot:item.actions="{}">-->
<!--            <MoreVerticalIcon/>-->
<!--          </template>-->

          <template v-slot:item.full_name="{ item }">
            <div class="d-flex">
              <v-avatar id="image" class="lg:tw-mt-5 tw-mr-3" color="#ECF4F7" size="30">
                <span class="initials">{{ item && Initials(item.full_name) }}</span>
              </v-avatar>
              <div>
                <p class="ml-sm-3 mt-sm-3 mb-0 fill">{{ item.full_name || "- -" }}</p>
                <p class="ml-sm-3 user">{{ item.admin_user_email || "- - -" }}</p>
              </div>
            </div>
          </template>

          <template v-slot:item.admin_type="{item}">
            <span class="fill text-capitalize">{{ statusText(item.admin_type) }}</span>
          </template>

          <template v-slot:item.date_invited="{item}">
            <span class="fill">{{ getDate(item.date_created) || '- - -' }}</span>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>

import {ChevronLeftIcon} from 'vue-feather-icons'
import InviteCompanyAdminModal from "@/components/dashboard/components/InviteCompanyAdminModal";
import {mapGetters} from "vuex";
import getSiteName from "@/services/utils";

export default {
  name: "CompanyAdminPage",
  components: {InviteCompanyAdminModal, ChevronLeftIcon},
  data() {
    return {
      sortBy:[
        {
          label:'All'
        },
        {
          label:'Primary Owner'
        },
        {
          label:'Admin'
        },
      ],
      newData: [],
      allAdmin: [],
      sort: '',
      search: '',
      loading: false,
      selected: [],
      currentPage: 1,
      itemsPerPage: 5,
      pageCount: 0,
      headers: [
        {text: "", value: "full_name"},
        {text: "", value: "admin_type"},
        {text: "", value: "date_invited"},
        {text: "", value: "actions", sortable: false},
      ],
    }
  },

  computed: {
    ...mapGetters('editCompany', ['getCompanyAdmin']),

    getDate() {
      return (date) => {
        let dateObjs = new Date(date);
        return dateObjs.toDateString()
        // return dateObjs.toLocaleDateString();
      };
    },
  },

  watch:{
    search: function (newValue, oldValue) {
      console.log(oldValue)
      this.getCompanyAdmin = [...this.getCompanyAdmin.filter(pool => (pool.name.toLowerCase().indexOf(newValue.toLowerCase()) !== -1))]
      console.log(this.getCompanyAdmin)
    },

    getCompanyAdmin: {
      handler(val) {
        this.allAdmin = JSON.parse(JSON.stringify(val))
        console.log(this.allAdmin);
        this.newData = this.allAdmin

      },
      immediate: true,
      deep: true
    },
  },

  created() {
    let siteName = getSiteName()
    this.$store.dispatch('editCompany/getCompanyAdmins', siteName)
  },

  methods: {
    statusText(name) {
      return name.replace(/_/g,' ')
    },
    Initials(item) {
      const fullName = item.split(' ');
      const initials = fullName && fullName.shift().charAt(0) + fullName.shift().charAt(0);
      return initials.toUpperCase();
    },

    filterData(val) {
      let files = this.getCompanyAdmin
      this.sort = val
      if (val === 'Primary Owner') {
        files = files.filter(item => item.admin_type === "primary_owner")
      }
      else if (val === 'Admin') {
        files = files.filter(item => item.admin_type === "admin")
      } else {
        files = files.filter(item => item.admin_type === "admin" || "primary_owner")
      }
      if (!files.length) {
        return this.newData = []
      }
      return this.newData = files
    },

    // traineeSearchFilter(value, search, item) {
    //   let email = () => item.full_name !== "" ? item.full_name.toLocaleLowerCase() : "";
    //   let name = () => (item.admin_email !== "" ? item.admin_email.toLocaleLowerCase() : "");
    //   let role = () => (item.admin_type !== "" ? item.admin_type.toLocaleLowerCase() : "")
    //   // let date = () => (item.date_invited !== "" ? item.date_invited.toDateString() : "")
    //
    //   if (search !== "") {
    //     let searchLowercase = search.toLocaleLowerCase();
    //     // let search = search.toDateString()
    //     if (
    //         email().indexOf(searchLowercase) === -1 &&
    //         name().indexOf(searchLowercase) === -1
    //         && role().indexOf(searchLowercase) === -1
    //
    //         // &&  date().indexOf(searchLowercase) === -1
    //     ) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   } else {
    //     return false;
    //   }
    // },
  }
}
</script>

<style scoped>
.admin {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}

.number {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #828282;
  margin-top: 20px;
}

.role {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #1E323F;
  margin-top: 15px;
  margin-right: 10px;
}

.initials {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
}

.user {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #9CABB5;
}

.fill {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
  text-transform: capitalize;
}

.filter {
  background: #FFFFFF;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  width:auto;
  height:47px
}

.filterFont{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #001343;
  width:auto
}
</style>
