<template>
  <div>
    <BaseButton @click="openDialog" width="156px" button-text="Invite Admin"/>
    <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        max-width="509">
      <template v-slot:default>
        <v-card style="border-radius: 16px" class="tw-p-6">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(sendInvite)">
              <div class="tw-flex tw-justify-between">
                <p class="header">Invite Admin</p>
                <v-card-actions class="justify-end tw-pt-0">
                  <XIcon @click="dialog = false"/>
                </v-card-actions>
              </div>
              <p class="text">Select role from the available options and enter the email address(es) of the user(s) to be invited to fill this role.</p>

              <div class="tw-flex tw-justify-between tw-mb-6">
                <p class="text tw-mt-3">Assign Role to User(s):</p>

                <div class="tw-w-36">
                  <v-select append-icon="mdi-chevron-down" v-model="role" :items="inviteRole" solo hide-details label="Admin"></v-select>
                </div>
              </div>
              <small>click the 'Enter' button after each input</small>
              <v-combobox
                  outlined
                  min-height="150px"
                  v-model="chips"
                  :items="items"
                  label="Enter an Email address"
                  chips
                  :append-icon="null"
                  data-vv-name="Email"
                  required
                  multiple
                  solo>
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      close-icon="mdi-close"
                      class="chips"
                      color="#0C8B99"
                      text-color="#fff"
                      @click="select"
                      @click:close="remove(item)">
                    <strong>{{ item }}</strong>&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
              <div>
                <v-card-actions class="justify-end tw-mt-5">
                  <BaseButton @click="dialog = false" class="tw-mr-3" outlined button-text="Cancel"/>
                  <BaseButton :loading="loading" width="156" @click="sendInvite" button-text="Send Invite" />
                </v-card-actions>
              </div>
            </form>
          </validation-observer>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>

import BaseButton from "@/components/UIComponents/BaseButton";
import XIcon from "vue-feather-icons/icons/XIcon";
import getSiteName from "@/services/utils";
import {inviteCompanyAdmin} from "@/services/api/APIService";

export default {
  name: "InviteCompanyAdminModal",
  components: {BaseButton, XIcon},
  data() {
    return {
      valid: true,
      dialog: false,
      loading: false,
      chips: [],
      role: 'admin',
      inviteRole: ['Admin',],
      items: [],
    }
  },

  methods: {
    openDialog() {
      this.dialog = true
    },

    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1)
      this.chips = [...this.chips]
    },
    validateEmail(){
      let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(this.chips.every(x => x.match(validRegex))){
        return true
      }
      else {
        this.$displaySnackbar({
          message: 'Enter a valid email address',
          success: false
        })
        return  false
      }
    },

    sendInvite() {
      if (this.validateEmail()) {
        let siteName = getSiteName()
      const data = {
        role: this.role,
        emails: this.chips
      }
      this.loading = true
        inviteCompanyAdmin(siteName, data).then(res => {
          console.log(res)
          this.$store.commit('setCompanyAdmin', res.data)
          this.$displaySnackbar({
            message: "Invite Sent",
            success: true
          })
          this.chips = ''
          this.dialog = false
        })
            .catch(error => {
              console.log(error)
              this.$displaySnackbar({
                message: 'Your Company has not been verified, Contact Enum Admin',
                // message: 'Something went wrong, Try again',
                success: false
              })
            })
            .finally(() => this.loading = false)
      }
    }
  }
}
</script>

<style scoped>
.text {
  font-family: "DM Sans", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}

.header {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #1E323F;
}

.chips {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}

element.style {
  min-height: 100px;
}
</style>